export const ProjectStatus = Object.freeze({
    DRAFT: "draft",
    // PENDING_REVIEW: "pending_review",
    // APPROVED: "approved",
    PUBLISHED: "published",
    IN_PROGRESS: "in_progress",
    ON_HOLD: "on_hold",
    COMPLETED: "completed",
    ARCHIVED: "archived",
    CANCELLED: "cancelled",
});